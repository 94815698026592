import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux";
//import logger from "redux-logger";
import { thunk } from "redux-thunk";
import websiteReducer from "./pages/website/website_page.reducer";
import loginReducer from "./pages/index/Login/login.reducer";
import navbarReducer from "./component/Navbar/FetchPage/Navbar.reducer";

const middleware = applyMiddleware(
   // logger,
    thunk
);
const root = combineReducers({
    websiteReducer,
    loginReducer,
    navbarReducer
  });
const storage = createStore(root,{},middleware);

export default storage;