
const baseurl = "https://encryption.edukrypt.app/lmsapi";
const apikey = "656ed5eb220cd";
const domain = "https://test.com";




export {
    baseurl,
    apikey,
    domain
} ;