import {
    NAV_ERROR,
    NAV_REQUEST,
    NAV_SUCCESS
} from "./Navbar.state";

import axios from "axios";
import {baseurl, apikey, domain } from '../../../baseurl'; 
axios.defaults.baseURL = baseurl;


const navbarFetch = (page)=>{
        return async (dispatch)=>
        {
            dispatch({
                type:NAV_REQUEST,
                payload:[]
            });
            try {
                const response = await axios.get(
                    '/page/'+page, 
                {
                    headers: {
                    'apikey': apikey,
                    'domain': domain,
                    }
                }
                );
               
                dispatch({
                    type:NAV_SUCCESS,
                    payload:response.data
                });
            } 
            catch (error) 
            {
                // Consider logging the error or handling it differently
                
                dispatch({
                    type:NAV_ERROR,
                    error:error
                })
            }
        }
}

export{
    navbarFetch
}