import {
    NAV_ERROR,
    NAV_REQUEST,
    NAV_SUCCESS
} from "./Navbar.state";

const Model = {
    isLoading:false,
    error:null,
    data:[]
}
const navbarReducer = (state=Model,action)=>{

    switch(action.type)
    {
        case NAV_REQUEST: return {
            ...state,
            isLoading:true

        }

        case NAV_SUCCESS : return {
            ...state,
            isLoading:false,
            data:action.payload
        }
        case NAV_ERROR : return {
            ...state,
            isLoading:false,
            error:action.error
        }


        default : return state;
    }

}

export default navbarReducer;